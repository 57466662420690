<ng-container *ngIf="cockpitBilanBarsComponentService.computeCollaboratorStat$ | async" />
<div
  class="bilan-bars"
  dougsBarWidth
  #barWidthDirective="dougsBarWidth"
  [ngClass]="{
    hoverable: (accountingStatsComponentService.isBilanModeToggle$ | async),
    'ml-8': hovered
  }"
>
  <div
    *ngIf="collaboratorStat.balanceSheets?.accountingSurveyCompleted"
    [ngStyle]="{ width: cockpitBilanBarsComponentService.completedWidth + '%' }"
    class="completed"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel
            : cockpitBilanBarsComponentService.completedWidth
            : collaboratorStat.balanceSheets.accountingSurveyCompleted
      "
      class="tiny color-white mr-4"
    >
      {{ collaboratorStat.balanceSheets.accountingSurveyCompleted }}
    </p>
  </div>
  <div
    *ngIf="collaboratorStat.balanceSheets?.prepared"
    [ngStyle]="{ width: cockpitBilanBarsComponentService.preparedWidth + '%' }"
    class="prepared"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel: cockpitBilanBarsComponentService.preparedWidth : collaboratorStat.balanceSheets.prepared
      "
      class="tiny color-white mr-4"
    >
      {{ collaboratorStat.balanceSheets.prepared }}
    </p>
  </div>
  <div
    *ngIf="collaboratorStat.balanceSheets?.accountingSurveyVerified"
    [ngStyle]="{ width: cockpitBilanBarsComponentService.verifiedWidth + '%' }"
    class="verified"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel
            : cockpitBilanBarsComponentService.verifiedWidth
            : collaboratorStat.balanceSheets.accountingSurveyVerified
      "
      class="tiny color-primary-700 mr-4"
    >
      {{ collaboratorStat.balanceSheets.accountingSurveyVerified }}
    </p>
  </div>
  <div
    *ngIf="collaboratorStat.balanceSheets?.revisionCompleted"
    [ngStyle]="{ width: cockpitBilanBarsComponentService.revisionWidth + '%' }"
    class="revision"
  >
    <p
      *ngIf="
        barWidthDirective.barWidth$()
          | showBarLabel
            : cockpitBilanBarsComponentService.revisionWidth
            : collaboratorStat.balanceSheets.revisionCompleted
      "
      class="tiny color-white mr-4"
    >
      {{ collaboratorStat.balanceSheets.revisionCompleted }}
    </p>
  </div>
</div>
