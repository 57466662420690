@if (termOfUseComponentService.refreshSignedTerms$ | async) {}
@if (subscriptionStateService.signedTerms$ | async; as signedTerms) {
  @if (signedTerms?.length) {
    <dougs-divider class="my-16" />
    <div class="subscription-line">
      <div class="subscription-line__left">
        <p class="small color-primary-700">Lettres de mission</p>
        <p class="small signed-terms" (click)="termOfUseComponentService.toggleOpenedSignedTermsList()">
          @if (!termOfUseComponentService.openedSignedTermsList$()) {
            <span>Tout afficher ({{ signedTerms.length }})</span>
          } @else {
            <span>Fermer</span>
          }
          <i
            class="fal ml-8"
            [ngClass]="{
              'fa-chevron-down': !termOfUseComponentService.openedSignedTermsList$(),
              'fa-chevron-up': termOfUseComponentService.openedSignedTermsList$()
            }"
          ></i>
        </p>
      </div>
      @if (company.subscription?.activeTerms && (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin) {
        <div class="subscription-line__right">
          <dougs-button
            color="admin"
            size="small"
            [fullWidth]="true"
            (click)="termOfUseComponentService.regenerateTermOfUse(company)"
          >
            <i class="fal fa-redo mr-8"></i>
            Regénérer
          </dougs-button>
        </div>
      }
    </div>
    @if (termOfUseComponentService.openedSignedTermsList$()) {
      <div class="form-layout mt-16">
        <div>
          <dougs-divider />
          <div class="signed-terms-item" *ngFor="let signedTerm of signedTerms; trackBy: 'id' | trackBy">
            <div
              class="signed-terms-content px-8 py-8"
              (click)="termOfUseComponentService.downloadSignedTerm(signedTerm)"
            >
              <div>
                <p class="tiny color-primary-700">
                  Lettre de mission {{ signedTerm.file.metadata?.terms?.acceptedAt | date: 'yyyy' }}
                </p>
                <p class="tiny color-gray-350" *ngIf="userStateService.loggedInUser?.isAccountantOrAdmin">
                  Lettre de mission de type "{{ signedTerm.file.metadata.terms.id }}", version
                  {{ signedTerm.file.metadata.terms.version }}, signée le
                  {{ signedTerm.file.metadata?.terms?.acceptedAt | date: 'dd/MM/yyyy' }}.
                </p>
              </div>
              <div>
                <i class="fal fa-chevron-right color-primary"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  }
}
